<template>
  <div>
    <v-row>
      <v-col> Prescribe initial <strong>basal</strong> doses</v-col>
    </v-row>
    <v-row data-testid="basal-once-weekly-section">
      <v-col cols="12" sm="3">
        <DoseInput
          :dose-ok.sync="doseWeeklyOk"
          :value="prescription.basal_once_weekly.value"
          @input="onDoubleDummyInput($event)"
          @change="onChange($event, 'PRESCRIBE_DOSE:WEEKLY_DOSE_VALUE')"
          :validation-rules="getValidationRules('basal_once_weekly')"
          :dosing-display-unit="dosingDisplayUnit('basal_once_weekly')"
          :default-initial-dose="getDefaultInitialDose('basal_once_weekly')"
          data-testid="input-basal-once-weekly"
          :can-deactivate="false"
          :required-text="'Prescribe initial weekly dose is required'"
        >
          Initial <strong>weekly</strong> dose
        </DoseInput>
      </v-col>
      <v-col sm="5">
        <FormLabel>
          Select a <strong>weekly</strong> dosing day for the patient
        </FormLabel>
        <v-select
          :value="dosingDay"
          placeholder="Choose a day"
          :items="dosingDayOptions"
          :rules="dosingDayRules"
          outlined
          @change="onChangeWeeklyDosingDay($event)"
          data-testid="input-basal-once-weekly-dosing-day"
          class="rounded-lg"
          :menu-props="{
            maxHeight: 380,
          }"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="3" data-testid="basal-once-daily-derived-section">
        <ReviewField
          :is-dose-value="true"
          :display-value="prescription.basal_once_daily.value"
          :dosing-display-unit="dosingDisplayUnit('basal_once_daily')"
          :is-derived="true"
          :trial-site-no="siteNo"
        >
          Initial <strong>daily</strong> dose
        </ReviewField>

        <v-text-field
          class="hidden"
          :value="prescription.basal_once_daily.value"
          :rules="derivedDoseRules"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import { debounce } from 'debounce'

import DoseInput from './_DoseInput'
import FormLabel from '../shared/FormLabel'
import initialPrescriptionMixin from './initialPrescriptionMixin'
import ReviewField from '../shared/ReviewField'
import loadingState from '@/constants/loadingState'
import prescriptionService from '@/services/prescription-service'
import { notificationMapActions } from '@/store/modules/notificationModule'
import NotificationType from '@/store/modules/notification/NotificationType'

export default {
  name: 'DoubleDummyInitialDose',
  mixins: [initialPrescriptionMixin],
  components: { ReviewField, FormLabel, DoseInput },
  props: {
    siteNo: { type: String, required: true },
  },
  data() {
    return {
      doseWeeklyOk: false,
      deriveDailyLoadingState: loadingState.INITIAL,
      dosingDayRules: [v => !!v || 'Please select a dosing day'],
      derivedDoseRules: [v => !!v || 'Derived dose is required'],
    }
  },
  methods: {
    ...notificationMapActions(),
    onDoubleDummyInput(value) {
      this.onInput(value, 'basal_once_weekly')
      this.onInput(null, 'basal_once_daily')
      this.deriveDailyDose()
    },
    deriveDailyDose() {
      if (!this.doseWeeklyOk) {
        this.onInput(null, 'basal_once_daily')
        this.deriveDailyLoadingState = loadingState.INITIAL
        return
      }

      this.deriveDailyLoadingState = loadingState.LOADING

      prescriptionService
        .deriveDose(
          this.newPatientInfo.patientNumber,
          this.siteNo,
          this.newPatientInfo.treatmentArm,
          parseFloat(this.prescription.basal_once_weekly.value)
        )
        .then(result => {
          const referenceWeekly = result.fromValue.toString()
          const currentWeekly =
            this.prescription.basal_once_weekly.value.toString()
          if (referenceWeekly === currentWeekly) {
            this.deriveDailyLoadingState = loadingState.LOAD_SUCCEEDED
            const dose = result.doseValue.toString()
            this.onInput(dose, 'basal_once_daily')
            this.onChange(dose, 'PRESCRIBE_DOSE:DAILY_DOSE_VALUE')
          } else if (!this.prescription.basal_once_daily.value) {
            this.deriveDailyDose()
          }
        })
        .catch(error => {
          this.deriveDailyLoadingState = loadingState.LOAD_ERRORED
          this.onInput(null, 'basal_once_daily')
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)

          this.spawnNotification({
            type: NotificationType.Error,
            title:
              'Weekly dose could not be translated to daily dose, please try again.',
          })
        })
    },
  },
  created() {
    this.deriveDailyDose = debounce(this.deriveDailyDose, 300)
  },
}
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
</style>
